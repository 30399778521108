<template>
    <div>
        <center><h3>Option for parameter : {{ parameterType ? parameterTypeStrings[parameterType-1] : '' }}</h3></center>
        <br>
        <div class="vvx-row x-col mb-6 w-full">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-3/4 w-full">
                    <div class="flex flex-wrap items-center justify-between">
                        <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name, or Description" />
                    </div>
                </div>
                <div class="vx-col sm:w-1/4 w-full">
                    <vs-button class="mr-3 mb-2 vs-button-dark" @click="getParameterMatrixOption(parameterType)">Search</vs-button>
                </div>
            </div>
        </div>
        <vs-table class="vx-col w-full" :data="optionParameter" stripe border>
            <template slot="thead">
                <vs-th>Code/Name</vs-th>
                <vs-th v-if="parameterType==17">Qty</vs-th>
                <vs-th>Description</vs-th>
                <vs-th style="flex:right; width: 10px;" v-if="optional.allow_action">Action</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td v-if="parameterType==8">
                        {{ (dt.Code) ? '('+dt.Code+') ' : '' }} {{dt.Name}}
                    </vs-td>
                    <vs-td v-else>
                        {{ (dt.code) ? '('+dt.code+') ' : '' }} {{dt.name}}
                    </vs-td>
                    <vs-td v-if="!([8,3,16,17, 12,13]).includes(parameterType)">{{ (dt.description) ? dt.description : '-' }}</vs-td>
                    <vs-td v-if="parameterType==8">{{ (dt.Description) ? dt.Description : '-' }}</vs-td>
                    <vs-td v-if="parameterType==3">{{ (dt.classification) ? dt.classification : '-' }}</vs-td>
                    <vs-td v-if="parameterType==17">
                        <input @keypress="isNumber($event)" class="vs-inputx vs-input--input" style="text-align:right" v-model="dt.qty"/>
                    </vs-td>
                    <vs-td v-if="([12, 13]).includes(parameterType) && !([8,3,16,17]).includes(parameterType)">{{ (dt.contact_name) ? dt.contact_name : '-' }}</vs-td>
                    <vs-td v-if="([16,17]).includes(parameterType)">{{ (dt.unit) ? dt.unit : '-' }}</vs-td>
                    <vs-td v-if="optional.allow_action"><feather-icon title="Delete" icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectTemplate(parameterType, dt)"/></vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <template>
            <div class="center">
                <vs-pagination v-model="page" :total="totalPage" />
            </div>
        </template>
    </div>
</template>

<script>
    import VueNumberInput from "@chenfengyuan/vue-number-input";
    export default {
        props: [],
        components: {
            VueNumberInput
        },
        mounted(){
            //
        },
        computed: {
            watchedProperties() {
                return `${this.parameterType}||${this.page}`;
            },
        },
        watch: {
            watchedProperties: function() {
                this.changePage(this.page)
            }
        },
        data: () => ({
            search: null,
            page:1,
            totalPage: 1,
            totalRecord: 0,
            parameterType: 0,
            // 9: customer group 1
            // 10: customer group 2
            // 11: customer group 3
            // 12: customer sold to (customers address [billing])
            // 13: customer ship to (customer address [shipping])
            // 14: division
            // 15: combo
            // 16: item (sku + hu) --> item_requisitions
            // 17: item (sku + hu) --> free_items
            // 18: Category 1
            // 19: Category 2
            // 20: Material 1
            // 21: Material 2
            // 22: Material 3
            parameterTypeStrings: ["Sales Area", "Territory", "Distribution Channel", "Customer Group", "Pricing", "Payment Term", "Delivery Type", "Promotion"
            , "Customer Group 1", "Customer Group 2", "Customer Group 3", "Customer Sold To", "Customer Ship To", "Customer Division", "Customer Combo"
            , "Item SKU", "Item SKU", "Item Category 1", "Item Category 2", "Item Material 1", "Item Material 2", "Item Material 3", "Payment Term", "Delivery Type", "Unit"],
            optionParameter: [],
            optional: {"allow_action": true},
        }),
        methods: {
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            selectTemplate(type, dataParameter) {
                // untuk handle perbedaan format penulisan response
                // ada yang camel case, dan ada yang snake

                // 2: territory
                // 3: customer_category (dist channel)
                // 5: pricing group (cust group 4)
                // 9: customer group 1
                // 10: customer group 2
                // 11: customer group 3
                // 12: customer sold to (customers)
                // 13: customer ship to (customer address)
                // 14: division
                // 15: combo
                // 16: item (sku + hu) --> item_requisitions
                // 17: item (sku + hu) --> free_items
                // 18: Category 1
                // 19: Category 2
                // 20: Material 1
                // 21: Material 2
                // 22: Material 3
                // 23: Payment Term
                // 24: Delivery Type
                let data = {}
                switch(type) {
                    // case 2:
                    //     data.id = dataParameter.id
                    //     data.code = dataParameter.code
                    //     data.name = dataParameter.name
                    //     break;
                    case 3:
                        data.id = dataParameter.id
                        data.code = dataParameter.name
                        data.name = dataParameter.name
                        break;
                    case 5:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    case 9:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    case 10:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    case 11:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    case 12:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    case 13:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    // case 14:
                    //     data.id = dataParameter.id
                    //     data.code = dataParameter.code
                    //     data.name = dataParameter.name
                    //     break;
                    // case 15:
                    //     data.id = dataParameter.id
                    //     data.code = dataParameter.code
                    //     data.name = dataParameter.name
                    //     break;
                    case 17:
                        data = dataParameter
                        if(isNaN(data.qty) || parseInt(data.qty)==0) {
                            this.$vs.notify({
                                color: "warning",
                                title: "Warning",
                                text: "Qty must be greater than 0",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                            return false
                        }
                        data.qty = parseInt(data.qty)
                        break;
                    case 18:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    case 19:
                        data.id = dataParameter.ID
                        data.code = dataParameter.code
                        data.name = dataParameter.name
                        break;
                    case 23:
                        data.id = dataParameter.id
                        data.code = dataParameter.name
                        data.name = dataParameter.name
                        break;
                    default:
                        data = dataParameter
                        data.id = (dataParameter.ID) ? dataParameter.ID : dataParameter.id
                }
                this.$emit("selectTemplate", {
                    type: type,
                    data: data,
                    optional: this.optional,
                });
                this.search = null
            },
            changePage(page) {
                this.getParameterMatrixOption(this.parameterType, page);
                return page;
            },
            setParameterType(type, optional={"category":"item_requisition", "idx":0, "allow_action": true,"params":{}}) {
                this.parameterType = type
                this.page = 1
                this.optional = optional
            },
            getParameterMatrixOption(type, page= 1) {
                this.$vs.loading();
                var parameter = {
                    limit: 10,
                    length: 10,
                    page: page,
                    offset: 10 * (page - 1),
                    search: this.search
                };

                // 2: territory
                // 3: customer_category (dist channel)
                // 5: pricing group (cust group 4)
                // 9: customer group 1
                // 10: customer group 2
                // 11: customer group 3
                // 12: customer sold to (customers address [billing])
                // 13: customer ship to (customer address [shipping])
                // 14: division
                // 15: combo
				        // 16: item (sku + hu) --> item_requisitions
                // 17: item (sku + hu) --> free_items
                // 18: Category 1
                // 19: Category 2
                // 20: Material 1
                // 21: Material 2
                // 22: Material 3
                // 23: Payment Term
                // 24: Delivery Type

                this.page = page
                this.parameterType = type
                let url = ""
                console.log("this.optional",this.optional)
                switch(type) {
                    case 1:
                        url = "/api/v1/master/territory-area"
                        break;
                    case 2:
                        url = "/api/v1/master/territory"
                        break;
                    case 3:
                        url = "/api/v1/master/customer-category"
                        break;
                    case 4:
                        url = "/api/v1/master/customer-group"
                        break;
                    case 5:
                        url = "/api/v1/master/pricing-group"
                        break;
                    case 6:
                        url = "/api/v1/master/payment-term"
                        break;
                    case 7:
                        url = "/api/v1/master/delivery-type"
                        break;
                    case 8:
                        url = "/api/v2/promotion"
                        break;
                    case 9:
                        url = "/api/v1/master/customer-group"
                        parameter.level = 1
                        break;
                    case 10:
                        url = "/api/v1/master/customer-group"
                        parameter.level = 2
                        break;
                    case 11:
                        url = "/api/v1/master/customer-group"
                        parameter.level = 3
                        break;
                    case 12:
                        url = "/api/v1/master/customer-address"
                        break;
                    case 13:
                        url = "/api/v1/master/customer-address"
                        break;
                    case 14:
                        url = "/api/v1/master/division"
                        break;
                    case 15:
                        url = "/api/v1/master/combosv2"
                        break;
                    case 16:
                        url = "/api/v1/master/list-item-unit"
                        if (this.optional.params&&this.optional.params.code_combo!="") {
                            parameter.is_combo = true
                            parameter.code_combo = this.optional.params.code_combo
                        }
                        break;
                    case 17:
                        url = "/api/v1/master/list-item-unit"
                        break;
                    case 18:
                        url = "/api/v1/master/item-category"
                        parameter.level = 1
                        break;
                    case 19:
                        url = "/api/v1/master/item-category"
                        parameter.level = 2
                        break;
                    case 20:
                        url = "/api/v1/master/item-material"
                        parameter.level = 1
                        break;
                    case 21:
                        url = "/api/v1/master/item-material"
                        parameter.level = 2
                        break;
                    case 22:
                        url = "/api/v1/master/item-material"
                        parameter.level = 3
                        break;
                    case 23:
                        url = "/api/v1/master/payment-term"
                        break;
                    case 24:
                        url = "/api/v1/master/delivery-type"
                        break;
                    case 25:
                        url = "/api/v1/master/unit"
                        break;
                }

                if(url=="") {
                    return true
                }

                this.$http.get(url, {
                    params: parameter
                }).then(resp => {
                    if (resp.code == 200) {
                        // handle beda format response
                        if(type==8) {
                            this.optionParameter = resp.data.Promotions;
                        } else {
                            this.optionParameter = resp.data.records;
                        }
                        console.log("this.optionParameter", this.optionParameter)
                        console.log("resp", resp)
                        this.totalPage = resp.data.total_page;
                        this.totalRecord = resp.data.total_record_search;
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    this.$vs.loading.close();
                });
            },
        },
    };
</script>
