<template>
    <div>
        <!-- main page -->
        <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Promotion Code, Currency Code, or Description" />
                        </div>
                    </div>
                </div>

                <vs-table :data="dataPromotion" stripe border>
                    <template slot="thead">
                        <vs-th>Promotion</vs-th>
                        <vs-th>Budget</vs-th>
                        <vs-th>Validity</vs-th>
                        <vs-th>Action</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td style="width:20%">
                                Bulletin Code : {{dt.group_code}}<br>
                                Code : {{dt.code}}<br>
                                Name : {{dt.name}}<br>
                                Description : {{dt.description}}<br>
                                Type : {{dt.type == 1 ? 'Discount' : 'Free Item' }}<br>
                            </vs-td>
                            <vs-td>
                                Code : {{dt.budget.code}} <br>
                                Description : {{dt.budget.description}} <br>
                                Budget : {{formatCurrency(dt.budget.value)}}
                            </vs-td>
                            <vs-td>
                                From : {{dateFormat(dt.valid_from)}} <br>
                                To : {{dateFormat(dt.valid_to)}}
                            </vs-td>
                            <vs-td>
                                <!-- MIX2-5021, ini semua dicomment karena tidak sesuai dg ticket MIX2-4951 -->
                                <div v-if="false">
                                  <div v-if="MonitoringType !='approval' && MonitoringType == undefined">
                                      <feather-icon v-if="(progress!='draft'&&progress!='inquiry')" title="Detail" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickDetail(dt)"/>
                                      <feather-icon v-if="(progress == 'draft' || progress=='inquiry')" title="Send to approval" icon="SendIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickSendToApproval(dt)"/>
                                      <feather-icon v-if="progress == 'approve'" title="Release" icon="CheckIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickRelease(dt)"/>
                                      <feather-icon v-if=" progress == 'active'" title="In-Active" icon="XIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickInActive(dt)"/>
                                      <feather-icon v-if="((progress != 'active' && progress!='pending') && progress=='draft' || progress=='inquiry')" title="Edit" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickEdit(dt)"/> <!-- MIX2-4079, tab active tidak boleh diedit -->
                                      <feather-icon v-if="(progress!='pending' && progress=='draft'|| progress=='inquiry')" title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="clickDelete(dt)"/>
                                  </div>
                                  <div v-if="MonitoringType =='approval' && MonitoringType != undefined">
                                      <feather-icon v-if="progress == 'pending'" title="Approve" icon="CheckIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickApprove(dt)"/>
                                      <feather-icon v-if="progress == 'pending'" title="Inquiry" icon="InfoIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickInquiry(dt)"/>
                                      <feather-icon v-if="progress == 'pending'" title="Reject" icon="XIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickReject(dt)"/>
                                      <feather-icon v-if="(progress!='pending'&&progress!='draft'&&progress!='cancelled'&&progress!='inquiry')" title="Detail" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickDetail(dt)"/>
                                  </div>
                                </div>
                                <!-- MIX2-5021, dikembalikan ke kondisi sebelum MIX2-4951 -->
                                <feather-icon v-if="MonitoringType =='approval' && progress == 'pending'" title="Approve" icon="CheckIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickApprove(dt)"/>
                                <feather-icon v-if="MonitoringType =='approval' && progress == 'pending'" title="Inquiry" icon="InfoIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickInquiry(dt)"/>
                                <feather-icon v-if="MonitoringType =='approval' && progress == 'pending'" title="Reject" icon="XIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickReject(dt)"/>
                                <feather-icon v-if="MonitoringType =='approval' ||(progress!='pending'&&progress!='draft'&&progress!='inquiry')||progress=='cancelled'" title="Detail" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickDetail(dt)"/> <!-- bisa view saja utk tab cancel-->
                                <feather-icon v-if="(progress == 'draft' || progress=='inquiry') && MonitoringType !='approval'" title="Send to approval" icon="SendIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickSendToApproval(dt)"/>
                                <feather-icon v-if="MonitoringType !='approval' && progress == 'approve'" title="Release" icon="CheckIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickRelease(dt)"/>
                                <feather-icon v-if="MonitoringType !='approval' && progress == 'active'" title="In-Active" icon="XIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickInActive(dt)"/>
                                <feather-icon v-if="MonitoringType !='approval' && ((progress != 'active' && progress!='pending') && (progress=='draft' || progress=='inquiry') && progress!='cancelled')" title="Edit" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickEdit(dt)"/> <!-- MIX2-4079, tab active tidak boleh diedit, edit tdk muncul di tab cancel -->
                                <feather-icon v-if="MonitoringType !='approval' && (progress!='pending' || progress=='draft'|| progress=='inquiry') && progress!='cancelled'" title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="clickDelete(dt)"/> <!-- delete tdk muncul di tab cancel -->
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
        <!--
        <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <detailSelectedPromotion :selectedPromotion="selectedPromotion"></detailSelectedPromotion>
                </div>
            </div>
        </vs-popup>
        -->
    </div>
</template>


<script>
// import detailSelectedPromotion from "../component/detail_selected_so.vue";
// import moment from 'moment'

export default {
    components: {
        // detailSelectedPromotion
    },
    props:
        ["progress","MonitoringType"],
        mounted() {
            this.selectedPromotion = this.dataPromotion
        },
        data() {
            return {
                selectedPromotion: {},
                dataPromotion: [{
                    base: null,
                    budget: {
                        ID: null,
                        code: null,
                        description: null
                    },
                    calculation: null,
                    code: null,
                    created_at: null,
                    deleted_at: null,
                    description: null,
                    id: null,
                    is_active: null,
                    item_requisition: [{}],
                    name: null,
                    step: [{}],
                    type: null,
                    unit: null,
                    valid_from: null,
                    valid_to: null
                }],

                limits: [10, 25, 50, 100],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                search: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,
                StsOpen:0,
                StsPending:1,
                StsApprove:2,
                StsCancel:3,
                StsReject:4,
                StsInquiry:5,
            };
        },
    watch: {
        watchedProperties: function() {
            this.getData();
        }
    },
    computed: {
        watchedProperties() {
            return `${this.territory}||${this.salesChannel}||${this.customerCategory}||${this.search}`;
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        dateFormat(date) {
            if (date) {
                // return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
                let dt = new Date(date)
                let d = dt.getDate()
                let m = dt.getMonth()+1
                let y = dt.getFullYear()
                return d+"/"+m+"/"+y
            }
        },

        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(promotion) {
            this.$emit("clickEdit", promotion);
        },
        clickDetail(promotion) {
            console.log("checkDetail1", promotion)
            this.$emit("clickDetail", promotion);
        },

        clickDelete(promotion) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You are about to cancel '"+promotion.code+"' ?",
                accept: this.actionDelete,
                parameters: promotion
            });
        },

		    actionDelete(promotion) {
			      this.$vs.loading();
            this.$http
            .post("api/v2/promotion/change-status", {
                id: promotion.id,
                code: promotion.code,
                status:this.StsCancel,
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "Cancel request sent successfully",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                    this.getData();
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                    this.getData();
                    this.$vs.loading.close();
                }
            });
		    },

        actionDelete2(promotion) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion/cancel", {
                    id: promotion.id,
                    code: promotion.code,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Promotion Promotion successfully cancelled",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        clickSendToApproval(promotion) {
            console.log("promotion",promotion)
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You will apply for approval '"+promotion.code+"' ?",
                accept: this.actionSendToApprove,
                parameters: promotion
            });
        },

        actionSendToApprove(promotion) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion/change-status", {
                    id: promotion.id,
                    code: promotion.code,
                    status:this.StsPending,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Approval request sent successfully",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        clickApprove(promotion) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Agree to this data '"+promotion.code+"' ?",
                accept: this.actionApprove,
                parameters: promotion
            });
        },

        actionApprove(promotion) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion/change-status", {
                    id: promotion.id,
                    code: promotion.code,
                    status:this.StsApprove,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Data has been successfully approved",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        clickReject(promotion) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You will reject this data '"+promotion.code+"' ?",
                accept: this.actionReject,
                parameters: promotion
            });
        },

        actionReject(promotion) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion/change-status", {
                    id: promotion.id,
                    code: promotion.code,
                    status:this.StsReject,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Data was successfully rejected",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        clickInquiry(promotion) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You will reject(inquiry) this data '"+promotion.code+"' ?",
                accept: this.actionInquiry,
                parameters: promotion
            });
        },

        actionInquiry(promotion) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion/change-status", {
                    id: promotion.id,
                    code: promotion.code,
                    status:this.StsInquiry,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Data was successfully rejected",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        clickRelease(promotion) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You will release this data '"+promotion.code+"' ?",
                accept: this.actionRelease,
                parameters: promotion
            });
        },

        actionRelease(promotion) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion/release", {
                    id: promotion.id,
                    code: promotion.code,
                    status:this.StsReject,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Data successfully released",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },
        clickInActive(promotion) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You will disable this data '"+promotion.code+"' ?",
                accept: this.actionInActive,
                parameters: promotion
            });
        },

        actionInActive(promotion) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion/in-active", {
                    id: promotion.id,
                    code: promotion.code,
                    status:this.StsReject,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Data deactivated successfully",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        closeDetail() {
            this.detail = false;
            this.$emit("selectPromotion", null);
        },

        page(page) {
            this.getData(page);
            return page;
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        getData(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            var parameter = {
                progress: this.progress,
                is_full_preload: true,
                search: this.search,
                limit: this.limitShow,
                offset: this.limitShow * (page - 1)
            };
            var endpoint ="/api/v2/promotion"
            if (this.MonitoringType=="approval") {
                endpoint +="/approval"
            }
            this.$http.get(endpoint, {
                params: parameter
            }).then(resp => {
                if (resp.code == 200) {
                    this.drawData = this.drawData + 1;
                    this.dataPromotion = resp.data.Promotions;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                    this.checkedAll = false;
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
